.lead-container{
  margin-bottom: 1rem;
}

.fontColor-highlight-major{
  color: #d29b23;
}

.fontColor-highlight-secondary{
  background-color:rgba(48, 90, 153, 0.3);
}
